import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { useEffect, useState,useRef } from 'react';
import UserMenu from './userMenu';

function HeaderMenu({reloadusercontext}) {
    const auth = useAuth();
    let navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userinitials, setUserInitials] = useState(false);
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [openMenuMobile, setOpenMenuMobile] = useState(false);
    const menuRef = useRef(null);

    const closeMenu = function () {
        setOpen(false);
    };

    useEffect(() => {
        if (auth && auth.isAuthenticated) {
            setIsAuthenticated(true);
        }
        if (auth && auth.user) {
            setUserInitials(auth.user?.profile.given_name.substr(0, 1) + auth.user?.profile.family_name.substr(0, 1));
        }
    }, [auth, userinitials]);

    const doLogout = async function () {
	auth.removeUser();
        auth.signoutRedirect();
	localStorage.clear();
        navigate('/login/?redirect=' + encodeURIComponent(document.location.href));
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                closeMenu();
            }
        };

        if (open) {
            const timeoutId = setTimeout(() => {
                closeMenu();
            }, 3000); // esempio di 5 secondi

            document.addEventListener('mousedown', handleClickOutside);

            return () => {
                clearTimeout(timeoutId);
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [open, closeMenu]);
    


    return (
        <>
		{isAuthenticated && (location.pathname==='/' || location.pathname==='/dashboard/')?(
            <div className='header-wrapper'>
				<div className="row g-0 align-items-center header-dash-row">
					<div className='col-auto'>
                        <a alt="Logo Giappichelli" target='_blank' href='https://www.giappichelli.it/' rel="noopener noreferrer">
                            <picture>
                                <source media="(max-width: 980px)" srcSet={`${process.env.PUBLIC_URL}/logo-mobile-w.png`} alt="Logo Giappichelli" />
                                <img src={`${process.env.PUBLIC_URL}/giappichelli-logo-w.png`} alt="Logo Giappichelli" />
						    </picture>
                        </a>
					</div>
					<div className='col search'>
						<form action={`${process.env.REACT_APP_PREFIX_MAGENTO}/catalogsearch/result/`} method='get'>
							<input type='text' name='q' placeholder='Cerca Titolo, Autore o ISBN' required />
							<input type='submit' style={{ display: 'none' }} />
						</form>
					</div>
                    {/* <div 
                            className='col-auto open-menu-mobile open-menu-dash'
                            onClick={() => setOpenMenuMobile(!openMenuMobile)}
                            aria-controls="collapse-menu-mobile"
                            aria-expanded={openMenuMobile}
                        >
                    </div> */}
                    <div ref={menuRef} className={`menu-mobile menu-mobile-dash ${openMenuMobile ? 'show' : ''}`} id="collapse-menu-mobile">
                        <nav>
                            <div className='row align-items-center g-0 p-0'>
                                <div className='col'>
                                    <h4>Menu</h4>
                                </div>
                                <div className='col-auto'>
                                    <img
                                        onClick={() => setOpenMenuMobile(!openMenuMobile)} 
                                        src={`${process.env.PUBLIC_URL}/close-menu.svg`} alt="Chiudi menu" 
                                    />
                                </div>
                            </div>

                            <ul>
                                <li>
                                    <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/studenti-home`}>Università</a>
                                    <ul>
                                        <li><a>Studenti</a></li>
                                        <li><a>Docenti</a></li>
                                    </ul>
                                </li>
                                <li><a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/professionisti-home`}>Professionale</a></li>
                                <li><a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/biblioteche-home`}>Enti e Biblioteche</a></li>
                                <li className='menu-activate'><a href='?show=activateProduct'>Attiva prodotto</a></li>
                                <li><a>FAQ</a></li>
                            </ul>
                        </nav>
                    </div>
                    <div className='col search-mobile'>
                        <form
                            className={`search-mobile-form ${openSearch ? 'show' : ''}`}
                            id="collapse-search"
                            action={`${process.env.REACT_APP_PREFIX_MAGENTO}/catalogsearch/result/`} method='get'
                        >
                            <input type='text' name='q' placeholder='Cerca Titolo, Autore o ISBN' required />
                            <input type='submit' style={{ display: 'none' }} />
                        </form>
                        <img
                            onClick={() => setOpenSearch(!openSearch)}
                            aria-controls="collapse-search"
                            aria-expanded={openSearch}
                            src={`${process.env.PUBLIC_URL}/search-mobile-w.svg`} alt="Cerca su Giappichelli"
                        />
                    </div>
					<div className='col-auto faq'>
						<Link to="/help-center/">
						<img src={`${process.env.PUBLIC_URL}/icon-question-w.svg`} alt="FAQ" />
						</Link>
					</div>
					<div className='col-auto open-menu'>
						<button
							onClick={() => setOpen(!open)}
							aria-controls="collapse-menu"
							aria-expanded={open}>{userinitials ? userinitials : 'U'}
						</button>
						<UserMenu open={open} doLogout={doLogout} closeMenu={()=>{closeMenu();}} reloadusercontext={reloadusercontext} />
					</div>
				</div>
			</div>
        ):(
            <>
                {(!['/register/','/register','/forgot-password','/forgot-password/','/profilo/primoaccesso','/profilo/primoaccesso/','/'].includes(location.pathname)) && <div className='header-wrapper header-row-top'>
                    <div className="row g-0 align-items-center header-row-top">
			{!['/attivaprodotto','/attivaprodotto/','/attivaomaggio','/attivaomaggio/','/attivaebook','/attivaebook/','/attivaebookedu','/attivaebookedu/','/attivadaecomm','/attivadaecomm/'].includes(location.pathname)?(<>
                        <div className='col-auto'><a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/studenti-home`}>università</a></div>
                        <div className='col-auto'><a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/professionisti-home`}>professionale</a></div>
                        <div className='col-auto'><a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/biblioteche-home`}>enti e biblioteche</a></div>
                        <div className='col d-flex justify-content-end'>
                            <a href="?show=activateProduct"><button><span>Attiva Prodotto</span></button></a>
                        </div>
			</>):(<></>)}
                    </div>
                </div>}
                <div className='header-wrapper header-row-bottom'>
                    <div className="row h-100 g-0 align-items-center">
                        <div className='col-auto'>
                            <a alt="Logo Giappichelli" href='https://www.giappichelli.it/' target='_blank' rel="noopener noreferrer">
                                <picture>
                                    <source media="(max-width: 980px)" srcSet={`${process.env.PUBLIC_URL}/logo-mobile.png`} alt="Logo Giappichelli" />
                                    <img src={`${process.env.PUBLIC_URL}/giappichelli-logo.png`} alt="Logo Giappichelli" />
                                </picture>
                            </a>
                        </div>
                        <div className='col search'>
                            <form action={`${process.env.REACT_APP_PREFIX_MAGENTO}/catalogsearch/result/`} method='get'>
                                <input type='text' name='q' placeholder='Cerca Titolo, Autore o ISBN' required />
                                <input type='submit' style={{ display: 'none' }} />
                            </form>
                        </div>
                        {/* <div 
                            className='col-auto open-menu-mobile'
                            onClick={() => setOpenMenuMobile(!openMenuMobile)}
                            aria-controls="collapse-menu-mobile"
                            aria-expanded={openMenuMobile}
                        >
                        </div> */}
                        <div ref={menuRef} className={`menu-mobile menu-mobile ${openMenuMobile ? 'show' : ''}`} id="collapse-menu-mobile">
                        <nav>
                            <div className='row align-items-center g-0 p-0'>
                                <div className='col'>
                                    <h4>Menu</h4>
                                </div>
                                <div className='col-auto'>
                                    <img
                                        onClick={() => setOpenMenuMobile(!openMenuMobile)} 
                                        src={`${process.env.PUBLIC_URL}/close-menu.svg`} alt="Chiudi menu" 
                                    />
                                </div>
                            </div>

                            <ul>
                                <li>
                                    <a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/studenti-home`}>Università</a>
                                    <ul>
                                        <li><a>Studenti</a></li>
                                        <li><a>Docenti</a></li>
                                    </ul>
                                </li>
                                <li><a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/professionisti-home`}>Professionale</a></li>
                                <li><a href={`${process.env.REACT_APP_PREFIX_MAGENTO}/biblioteche-home`}>Enti e Biblioteche</a></li>
                                <li className='menu-activate'><a href='?show=activateProduct'>Attiva prodotto</a></li>
                                <li><a>FAQ</a></li>
                            </ul>
                        </nav>
                    </div>
                        <div className='col search-mobile'>
                            <form
                                className={`search-mobile-form ${openSearch ? 'show' : ''}`}
                                id="collapse-search"
                                action={`${process.env.REACT_APP_PREFIX_MAGENTO}/catalogsearch/result/`} method='get'
                            >
                                <input type='text' name='q' placeholder='Cerca Titolo, Autore o ISBN' required />
                                <input type='submit' style={{ display: 'none' }} />
                            </form>
                            <img
                                onClick={() => setOpenSearch(!openSearch)}
                                aria-controls="collapse-search"
                                aria-expanded={openSearch}
                                src={`${process.env.PUBLIC_URL}/search-mobile.svg`} alt="Cerca su Giappichelli"
                            />
                        </div>
                        <div className='col-auto faq'>
                            <Link to="/help-center/">
                            <img src={`${process.env.PUBLIC_URL}/icon-question.svg`} alt="FAQ" />
                            </Link>
                        </div>
                        <div className='col-auto open-menu'>
                            {auth.isAuthenticated ? (<>
                                <button
                                    className='active-user'
                                    onClick={() => setOpen(!open)}
                                    aria-controls="collapse-menu"
                                    aria-expanded={open}>{userinitials ? userinitials : 'U'}
                                </button>
                                <UserMenu open={open} doLogout={doLogout} closeMenu={() => { closeMenu(); }} reloadusercontext={reloadusercontext} />
                            </>) : (
                                <button><Link to="/login/">Accedi</Link></button>
                            )}
                        </div>
                    </div>
                </div>
		    </>)}
        </>
    )
}

export default HeaderMenu;
